import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addToken, getUserToken } from "../../../../store/reducers/auth";
import constants from "../../../../utils/constants";

const TestCreditReport = () => {
  const { userId } = useParams();
  const [success, setSuccess] = useState(false);
  const [userToken, setUserToken] = useState("");
  const dispatch = useDispatch();

  const user = useSelector((store) => store?.auth?.data);

  useEffect(() => {
    dispatch(getUserToken({ userId, isTestEnv: true })).then((response) => {
      console.log('response', response)
      switch (response?.meta?.requestStatus) {
        case constants.THUNK_STATUS.FULFILLED:
          setUserToken(response?.payload?.userToken);
          return;

        default:
          return;
      }
    });
    window.addEventListener("array-event", handleArrayEvent);

    return () => {
      window.removeEventListener("array-event", handleArrayEvent);
    };
  }, [userToken, userId]);

  const handleArrayEvent = (arrayEvent) => {
    const { tagName, event, metadata = {} } = arrayEvent.detail;

    if (event === "success" && Object.keys(metadata).length > 0) {
      setSuccess(true);
      setUserToken(metadata?.["user-token"]);
      dispatch(
        addToken({
          userId: userId,
          userToken: metadata?.["user-token"],
          isTestEnv: true,
        })
      );
    }
  };

  return (
    <div>
      {userToken && (
        <array-credit-report
          appKey={process.env.REACT_APP_APP_KEY}
          userToken={userToken}
          sandbox="true"
          defaultBureau="efx"
        ></array-credit-report>
      )}

      {!userToken && (
        <array-authentication-kba
          appKey={process.env.REACT_APP_APP_KEY}
          sandbox="true"
          userId={userId}
          showResultPages="true"
        ></array-authentication-kba>
      )}
    </div>
  );
};

export default TestCreditReport;
