import axios from "axios";
import constants from "./constants";
import endpoints from "./endpoint";

// const http = axios.create({
//   baseURL: constants.API_BASE_ENDPOINT,
// });

// Create two different Axios instances
const http = axios.create({
  baseURL: constants.API_BASE_ENDPOINT,
});

const httpTest = axios.create({
  baseURL: constants.API_Test_BASE_ENDPOINT,
});

export const setupInterceptors = (store, httpInstance) => {
  httpInstance.interceptors.request.use((request) => {
    const CreditGeniusAdminAccessToken =
      store.getState().auth?.data?.tokens?.accessToken;

    if (CreditGeniusAdminAccessToken) {
      request.headers.Authorization = "Bearer " + CreditGeniusAdminAccessToken;
    }

    return request;
  });

  httpInstance.interceptors.response.use(
    (response) => {
      return response;
    },

    async (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          const response = await axios.patch(
            constants.API_BASE_ENDPOINT + endpoints.auth.renewToken,
            {
              userType: constants.USER_TYPE.ADMINISTRATOR,
              emailAddress: store.getState().auth?.data?.user?.emailAddress,
              refreshToken: store.getState().auth?.data?.tokens?.refreshToken,
            }
          );

          originalRequest.headers.Authorization =
            "Bearer " + response.data.tokens.accessToken;

          return httpInstance(originalRequest);
        } catch (error) {
          // refresh-token endpoint failed for some reason so new access token cannot be fetched at the moment. Redirect to /login page
          localStorage.clear();
        }
      }
      // Return any other errors (except 401)
      return Promise.reject(error);
    }
  );
};

export const initializeInterceptors = (store) => {
  setupInterceptors(store, http);
  setupInterceptors(store, httpTest);
};

const selectHttpInstance = (condition) => {
  return !condition ? http : httpTest;
};
/**
 *
 * @param {string} endpoint - Path to resource endpoint
 * @param {import("axios").AxiosRequestConfig} extraConfig - additional axios settings for the request
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchGetRequest = async (
  endpoint,
  extraConfig = {},
  condition
) => {
  const http = selectHttpInstance(condition);
  try {
    return await http.get(endpoint, {
      ...extraConfig,
    });
  } catch (err) {
    throw err.response;
  }
};

/**
 *
 * @param {string} endpoint - Path to resource endpoint
 * @param {any} payload - Required API inputs
 * @param {import("axios").AxiosRequestConfig} extraConfig - additional axios settings for the request
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchPostRequest = async (
  endpoint,
  payload,
  extraConfig = {},
  condition
) => {
  const http = selectHttpInstance(condition);
  try {
    return await http.post(endpoint, payload, {
      ...extraConfig,
    });
  } catch (error) {
    throw error.response;
  }
};

/**
 *
 * @param {string} endpoint - Path to resource endpoint
 * @param {any} payload - Required API inputs
 * @param {import("axios").AxiosRequestConfig} extraConfig - additional axios settings for the request
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchMultipartPostRequest = async (
  endpoint,
  payload,
  extraConfig = {},
  condition
) => {
  const http = selectHttpInstance(condition);
  try {
    return await http.post(endpoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...extraConfig,
    });
  } catch (error) {
    throw error.response;
  }
};

/**
 *
 * @param {string} endpoint - Path to resource endpoint
 * @param {any} payload - Required API inputs
 * @param {import("axios").AxiosRequestConfig} extraConfig - additional axios settings for the request
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchPutRequest = async (
  endpoint,
  payload,
  extraConfig = {},
  condition
) => {
  const http = selectHttpInstance(condition);
  try {
    return await http.put(endpoint, payload, { ...extraConfig });
  } catch (err) {
    throw err.response;
  }
};

/**
 *
 * @param {string} endpoint - Path to resource endpoint
 * @param {any} payload - Required API inputs
 * @param {import("axios").AxiosRequestConfig} extraConfig - additional axios settings for the request
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchMultipartPutRequest = async (
  endpoint,
  payload,
  extraConfig = {},
  condition
) => {
  const http = selectHttpInstance(condition);
  try {
    return await http.put(endpoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...extraConfig,
    });
  } catch (error) {
    throw error.response;
  }
};

/**
 *
 * @param {string} endpoint - Path to resource endpoint
 * @param {any} payload - Required API inputs
 * @param {import("axios").AxiosRequestConfig} extraConfig - additional axios settings for the request
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchPatchRequest = async (
  endpoint,
  payload,
  extraConfig = {},
  condition
) => {
  const http = selectHttpInstance(condition);
  try {
    return await http.patch(endpoint, payload, { ...extraConfig });
  } catch (error) {
    throw error.response;
  }
};

/**
 *
 * @param {string} endpoint - Path to resource endpoint
 * @param {any} payload - Required API inputs
 * @param {import("axios").AxiosRequestConfig} extraConfig - additional axios settings for the request
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchMultipartPatchRequest = async (
  endpoint,
  payload,
  extraConfig = {},
  condition
) => {
  const http = selectHttpInstance(condition);
  try {
    return await http.patch(endpoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...extraConfig,
    });
  } catch (error) {
    throw error.response;
  }
};

/**
 *
 * @param {string} endpoint - Path to resource endpoint
 * @param {import("axios").AxiosRequestConfig} extraConfig - additional axios settings for the request
 * @returns {Promise<import("axios").AxiosResponse<any>>}
 */
export const fetchDeleteRequest = async (
  endpoint,
  extraConfig = {},
  condition
) => {
  const http = selectHttpInstance(condition);
  try {
    return await http.delete(endpoint, { ...extraConfig });
  } catch (err) {
    throw err.response;
  }
};
