import './App.css';
import { Suspense } from 'react';
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import AppRoutes from "./AppRoutes";

function App() {
  return (
    <Suspense fallback={<p>Loading</p>}>
    <ErrorBoundary FallbackComponent={() => <p>Something went wrong</p>}>
      <HelmetProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </HelmetProvider>
    </ErrorBoundary>
  </Suspense>
  );
}

export default App;
