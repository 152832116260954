// import React from 'react';
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom'
import Header from "../../shared/header/header";
import Footer from "../../shared/footer/footer";
import 'owl.carousel/dist/assets/owl.theme.default.css';
import styles from "./faq.module.scss";


const testimonialScrl = {
  responsive: {
    0: {
        items: 1,
    },
    768: {
        items: 2,
    },
    1261: {
        items: 3,

    }
  },
};


const Faq = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const header = document.querySelector(".siteTopNavbar");
    const scrollTop = window.scrollY;
    if (header) {
      const navPosition = header.offsetTop;
      const navTop = window.scrollY || document.documentElement.scrollTop;
      if (navPosition < navTop) {
        header?.classList?.add("stickyHeader");
      } else {
        header?.classList?.remove("stickyHeader");
      }
    }
  };
  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
    document.body.classList.toggle("body-fixed");
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - (window.innerWidth < 992 ? 150 : 120),
        behavior: "smooth",
      });
    }
  };

  const handleMenuClick = (sectionId) => {
    toggleMenu();
    scrollToSection(sectionId);
  };

  const handleMenuLinkClick = (sectionId) => {
    setIsMenuOpen(false);
    scrollToSection(sectionId);
  };



  return (
    <>
    {/* <Header/> */}
    
    <div className={`${styles.topMainHdr} siteTopNavbar ${isMenuOpen ? "show-nav" : ""}`}>      
        <div className="container">  
        <div className={styles.hdrRow}>        
            <div className={styles.hdrLogo}>
              <a href={"/"}>
                <img src={"../../images/logo.png"} alt="Logo" />
              </a>
            </div>
            <div className={styles.hdrMenuPrt}>
              <button
                className={`navbar-toggler ${isMenuOpen ? "open" : ""}`}
                type="button"
                onClick={toggleMenu}
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div className={styles.headerMenu}>
                <nav className="navbar navbar-light navbar-expand-lg">
                  <div
                    className={`collapse navbar-collapse ${
                      isMenuOpen ? "show" : ""
                    }`}
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav">
                    <li>
                        <Link to="/#about-sec" onClick={() => handleMenuClick("about-sec")}> {/* Replaced href with to */}
                          About Credit Secret
                        </Link>
                      </li>
                      <li>
                      <Link to="/#personalCredit"
                          onClick={() => {                            
                            handleMenuClick("personalCredit");
                          }}
                        >
                          Personal Credit
                        </Link>
                      </li>
                      <li>
                        <a
                          href="/#creditGenius"
                          onClick={() => {                           
                            handleMenuClick("creditGenius");
                          }}
                        >
                          Credit Genius
                        </a>
                      </li>
                      <li>
                        <a
                          href="/#creditGames"
                          onClick={() => {                            
                            handleMenuClick("creditGames");
                          }}
                        >
                          Credit Games
                        </a>
                      </li>
                      <li>
                        <a
                          href="/#creditSimulator"
                          onClick={() => {                           
                            handleMenuClick("creditSimulator");
                          }}
                        >
                          Credit Simulator
                        </a>
                      </li>

                      <li>
                        <a
                          href="/#downloadApp"
                          onClick={() => {                            
                            handleMenuClick("downloadApp");
                          }}
                        >
                          Download App
                        </a>
                      </li>
                      
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

    {/* ================ Banner Section Start ================ */}
    <section className={styles.faqBannerSec}>
      <div className="container">
        <div className={styles.bannerRow}>        
          <h4> <img src={"../../images/q-icon.png"} alt="icon" /> How can I request the deletion of my account and associated data?</h4>
          <div className={styles.dFlex}>
          <div className={styles.marGap}><img src={"../../images/a-icon.png"} alt="icon" /></div>
          <p> Users can request account deletion by contacting our support team via email at <a href='mailto:info.tgacs@gmail.com'>info.tgacs@gmail.com</a>
, providing their account details and any additional required verification.</p>
          </div>


          <h4> <img src={"../../images/q-icon.png"} alt="icon" /> What information do I need to provide to request account deletion?</h4>
          <div className={styles.dFlex}>
          <div className={styles.marGap}><img src={"../../images/a-icon.png"} alt="icon" /></div>
          <p> Please provide your full name, email address associated with your account, and any identity verification document if requested.</p>
          </div>

          <h4> <img src={"../../images/q-icon.png"} alt="icon" /> How long will it take to process my deletion request?</h4>
          <div className={styles.dFlex}>
          <div className={styles.marGap}><img src={"../../images/a-icon.png"} alt="icon" /></div>
          <p> Account deletion requests are processed within 30 days from the date of the request.</p>
          </div>

          <h4> <img src={"../../images/q-icon.png"} alt="icon" /> What happens to my data after my account is deleted?</h4>
          <div className={styles.dFlex}>
          <div className={styles.marGap}><img src={"../../images/a-icon.png"} alt="icon" /></div>
          <p> Upon deletion, your data is removed from active databases immediately but will remain in backups for up to 60 days before complete deletion.Is the deletion of my account and data reversible?Deletion is final. Users have a 7-day grace period to cancel their deletion request.</p>
          </div>

          <h4> <img src={"../../images/q-icon.png"} alt="icon" /> Will all my data be deleted, including backups and logs?</h4>
          <div className={styles.dFlex}>
          <div className={styles.marGap}><img src={"../../images/a-icon.png"} alt="icon" /></div>
          <p> All personal and financial data associated with your account, including backups and logs, will be deleted.</p>
          </div>

          <h4> <img src={"../../images/q-icon.png"} alt="icon" /> Do I need to unsubscribe from newsletters or marketing emails separately after my account is deleted?</h4>
          <div className={styles.dFlex}>
          <div className={styles.marGap}><img src={"../../images/a-icon.png"} alt="icon" /></div>
          <p>No, account deletion includes automatic unsubscription from all communications.</p>
          </div>

          <h4> <img src={"../../images/q-icon.png"} alt="icon" /> What should I do if I encounter issues during the account deletion process?</h4>
          <div className={styles.dFlex}>
          <div className={styles.marGap}><img src={"../../images/a-icon.png"} alt="icon" /></div>
          <p>Please contact our support team via <a href='mailto:info.tgacs@gmail.com'>info.tgacs@gmail.com</a> for assistance.</p>
          </div>
          
          <h4> <img src={"../../images/q-icon.png"} alt="icon" /> How will I know once my account and data have been successfully deleted?</h4>
          <div className={styles.dFlex}>
          <div className={styles.marGap}><img src={"../../images/a-icon.png"} alt="icon" /></div>
          <p>You will receive an email confirmation once your account and all associated data have been deleted.</p>
          </div>

          <h4> <img src={"../../images/q-icon.png"} alt="icon" /> Are there any conditions under which my deletion request could be denied?</h4>
          <div className={styles.dFlex}>
          <div className={styles.marGap}><img src={"../../images/a-icon.png"} alt="icon" /></div>
          <p>Deletion requests may be denied if there are pending transactions, disputes, or legal holds on your account.</p>
          </div>




        </div>
      </div>
    </section>
    {/* ================ Banner Section End ================ */}





    






  



    
    <Footer/>
    </>
  );
};

export default Faq;
