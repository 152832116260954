import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link component
import styles from "./header.module.scss";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isPressMenuOpen, setIsPressMenuOpen] = useState(false);
  const [isBuzzMenuOpen, setIsBuzzMenuOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const header = document.querySelector(".siteTopNavbar");
    const scrollTop = window.scrollY;
    if (header) {
      const navPosition = header.offsetTop;
      const navTop = window.scrollY || document.documentElement.scrollTop;
      if (navPosition < navTop) {
        header?.classList?.add("stickyHeader");
      } else {
        header?.classList?.remove("stickyHeader");
      }
    }
  };
  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
    document.body.classList.toggle("body-fixed");
  };
  const togglePressMenu = () => {
    setIsBuzzMenuOpen(false)
    setIsPressMenuOpen((prevState) => !prevState);
    document.body.classList.toggle("body-fixed");
  };
  const toggleBuzzMenu = () => { 
    setIsPressMenuOpen(false)   
    setIsBuzzMenuOpen((prevState) => !prevState);
    document.body.classList.toggle("body-fixed");
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - (window.innerWidth < 992 ? 150 : 120),
        behavior: "smooth",
      });
    }
  };

  const handleMenuClick = (sectionId) => {
    toggleMenu();
    scrollToSection(sectionId);
  };

  const handleMenuLinkClick = (sectionId) => {
    setIsMenuOpen(false);
    scrollToSection(sectionId);
  };

  return (
    <>
      <div className={`${styles.topMainHdr} siteTopNavbar ${isMenuOpen ? "show-nav" : ""}`}>      
        <div className="container">  
        <div className={styles.hdrRow}>        
            <div className={styles.hdrLogo}>
              <Link to={"/"}> {/* Replaced <a> with <Link> */}
                <img src={"../../images/logo.png"} alt="Logo" />
              </Link>
            </div>
            <div className={styles.hdrMenuPrt}>
              <button
                className={`navbar-toggler ${isMenuOpen ? "open" : ""}`}
                type="button"
                onClick={toggleMenu}
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </button>
              <div className={styles.headerMenu}>
                <nav className="navbar navbar-light navbar-expand-lg">
                  <div
                    className={`collapse navbar-collapse ${
                      isMenuOpen ? "show" : ""
                    }`}
                    id="navbarSupportedContent"
                  >
                    <ul className="navbar-nav">
                      <li>
                        <Link to="/#about-sec" onClick={() => handleMenuClick("about-sec")}> {/* Replaced href with to */}
                          About Credit Secret
                        </Link>
                      </li>
                      <li>
                        <Link to="/#personalCredit" onClick={() => handleMenuClick("personalCredit")}> {/* Replaced href with to */}
                          Personal Credit
                        </Link>
                      </li>
                      <li>
                        <Link to="/#creditGenius" onClick={() => handleMenuClick("creditGenius")}> {/* Replaced href with to */}
                          Credit Genius
                        </Link>
                      </li>
                      <li>
                        <Link to="/#creditGames" onClick={() => handleMenuClick("creditGames")}> {/* Replaced href with to */}
                          Credit Games
                        </Link>
                      </li>
                      <li>
                        <Link to="/#creditSimulator" onClick={() => handleMenuClick("creditSimulator")}> {/* Replaced href with to */}
                          Credit Simulator
                        </Link>
                      </li>

                      <li>
                        <Link to="/#downloadApp" onClick={() => handleMenuClick("downloadApp")}> {/* Replaced href with to */}
                          Download App
                        </Link>
                      </li>
                      <li onClick={togglePressMenu} className={`dropdown ${isPressMenuOpen ? "open" : ""}`}>
                        <Link className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                          Press
                        </Link>
                        <ul className="dropdown-menu" role="menu">
                          <li>
                            <Link target="_blank" to="https://v.lemon8-app.com/s/djykMSspR">
                            Lemon8
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://the-credit-genius.en.softonic.com/iphone">
                            Softonic
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://medium.com/@yourprshop/a-game-changer-in-credit-monitoring-the-credit-genius-app-a660658e7277">
                              Medium Magazine
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://ventsmagazine.com/2023/05/06/an-overview-of-the-great-american-credit-secret/">
                            Vents Magazine
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://medium.com/@abuhurairaramay/the-credit-genius-app-revolutionizing-credit-monitoring-with-engaging-features-d9623d20e822">
                              Medium Magazine
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://onewestmagazine.com/magazine/2024/05/10/the-credit-genius-app-a-new-era-in-credit-monitoring/">
                            One West Magazine
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://influencerage.com/credit-management-made-simple-the-credit-genius-apps-user-friendly-approach/">
                            Influencerage.com
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://techplanet.today/post/the-credit-genius-app-transforming-credit-monitoring-with-innovative-features">
                            Tech Planet
                            </Link>
                          </li>
                          </ul>
                      </li>

                      <li onClick={toggleBuzzMenu} className={`dropdown ${isBuzzMenuOpen ? "open" : ""}`}>
                        <Link className="dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                          Buzz
                        </Link>                        
                         <ul className="dropdown-menu" role="menu">                          
                          <li>
                            <Link target="_blank" to="https://www.allnewsbuzz.com/the-credit-genius-app-pioneering-credit-monitoring-with-a-launch-set-for-june-1/">
                            All News Buzz
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://www.technewsvision.com/the-credit-genius-app-setting-a-new-benchmark-in-credit-monitoring-launching-june-1/">
                            Tech News Vision
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://www.technewsvision.com/the-credit-genius-app-mastering-credit-with-games-and-ai/">
                            Tech News Vision
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://losangelers.com/article/level-up-your-credit-score-the-credit-genius-app-your-personalized-credit-coach/">
                            The Los Angelers
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://techbullion.com/gamifying-credit-fitness-the-credit-genius-app-makes-building-credit-fun/">
                            Tech Bullion
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://www.thesunbulletin.com/achieve-credit-mastery-with-the-credit-genius-app-your-personalized-financial-advisor/">
                            The Sun Bulletin
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://www.newedgetimes.com/unlock-financial-freedom-with-the-credit-genius-apps-ai-driven-solutions/">
                            New Edge Times
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://www.newedgetimes.com/unlock-financial-freedom-with-the-credit-genius-apps-ai-driven-solutions/">
                            IMDb
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://www.imdb.com/name/nm16134877/">
                            IMDb 2
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://www.crunchbase.com/organization/the-credit-genius-app">
                            Crunchbase
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://en.wikialpha.org/wiki/The_Credit_Genius_App">
                            WikiAlpha
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://about.me/credit-genius-app/">
                            About
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://www.quora.com/What-is-The-Credit-Genius-App?ch=10&oid=191791781&share=0ba5ba95&srid=3L2skp&target_type=question">
                            Quora
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://www.quora.com/Is-The-Credit-Genius-App-legit">
                            Quora
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://www.reddit.com/r/creditgenius/s/YGBdbhw53Q">
                            Reddit
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://www.reddit.com/r/creditgenius/s/04YMOjqJLH">
                            Reddit
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://about.me/credit-genius-app">
                            About Me
                            </Link>
                          </li>
                          <li>
                            <Link target="_blank" to="https://www.yext.com/partnerpages/aroundme/credit-genius-west-hollywood-california-us-0a38c2">
                            Around Me
                            </Link>
                          </li>
                        </ul>
                  
                      </li>


                      
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
